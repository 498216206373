//* ======================= posición nav responsive ==========================

// const navContainerResponsive = document.querySelector(".responsive-menu");

// const navContentResponsive = document.getElementById(
//   "navbarToggleExternalContent"
// );

// const cloneNavContentResponsive = navContentResponsive.cloneNode(true);

// navContainerResponsive.appendChild(cloneNavContentResponsive);

// navContainerResponsive.removeChild(navContentResponsive);

//* ================== success stories ====================
const successStoriesAnchor =
    document.getElementById("flush-headingOne").firstElementChild;
const href = successStoriesAnchor.getAttribute("href");

successStoriesAnchor.addEventListener("click", function(e) {
    window.location.assign(window.location.origin + href);
});

//* ======================= what we do ========================

const whatWeDoAnchor =
    document.getElementById("flush-headingThree").firstElementChild
    .firstElementChild;

whatWeDoAnchor.addEventListener("click", function(e) {
    const href = whatWeDoAnchor.getAttribute("href");
    window.location.assign(window.location.origin + href);
});

//* ===================== nav item =========================

const navItems = document.getElementsByClassName("nav__item");
const successStories = navItems[1];
const solutions = navItems[3];
const whatWeDo = navItems[5];
const aboutUs = navItems[6];
if (window.location.pathname.split("/")[1] === "en") {
    switch (window.location.pathname.split("/")[2]) {
        case "what-we-do":
            whatWeDo.style.color = "#10849e";
            break;

        case "solutions":
            solutions.style.color = "#10849e";
            break;

        case "about":
            aboutUs.style.color = "#10849e";
            break;

        case "success-stories":
            successStories.style.color = "#10849e";
            break;
 case "success-stories":
            successStories.style.color = "#10849e";
            break;
        default:
            break;
    }
} else {
    switch (window.location.pathname.split("/")[1]) {
        case "what-we-do":
            whatWeDo.style.color = "#10849e";
            break;

        case "solutions":
            solutions.style.color = "#10849e";
            break;

        case "about":
            aboutUs.style.color = "#10849e";
            break;

        case "success-stories":
            successStories.style.color = "#10849e";
            break;

        default:
            break;
    }
}

//* ======================== buttons floating on scrolling =====================

(() => {
    const html = document.querySelector("html");
    const buttonFloatingBackStart = document.getElementsByClassName(
        "button__floating--back-start"
    )[0];

    const buttonFloatingWhatsapp = document.getElementsByClassName(
        "button__floating--whatsapp"
    )[0];

    const footer = document.getElementsByClassName("footer")[0];

    document.addEventListener(
        "scroll",
        (event) => {
            if (html.scrollTop > 700) {
                buttonFloatingBackStart.style.display = "flex";
            } else {
                buttonFloatingBackStart.style.display = "none";
            }
        }, { passive: true }
    );

    document.addEventListener(
        "scroll",
        (event) => {
            const htmlScrollBottom = html.scrollHeight - (html.scrollTop + html.clientHeight);
            if (htmlScrollBottom < footer?.scrollHeight + 21) {
                buttonFloatingWhatsapp.style.display = "flex";
            } else {
                buttonFloatingWhatsapp.style.display = "flex";
            }
        },

        { passive: true }
    );
})();
//* =================== facebook ======================
// const body = document.getElementsByTagName("body")[0];
// body.insertAdjacentHTML("afterbegin", '<div id="fb-root"></div>');
// const facebookIcon = document.getElementById("fb-root");
// facebookIcon.insertAdjacentHTML(
//   "afterend",
//   `
// <div id="fb-customer-chat" class="fb-customerchat">
// </div>`
// );

// var chatbox = document.getElementById("fb-customer-chat");
// chatbox.setAttribute("page_id", "719123261565914");
// chatbox.setAttribute("attribution", "biz_inbox");

// window.fbAsyncInit = function () {
//   FB.init({
//     xfbml: true,
//     version: "v13.0",
//   });
// };

// (function (d, s, id) {
//   var js,
//     fjs = d.getElementsByTagName(s)[0];
//   if (d.getElementById(id)) return;
//   js = d.createElement(s);
//   js.id = id;
//   js.src = "https://connect.facebook.net/es_LA/sdk/xfbml.customerchat.js";
//   fjs.parentNode.insertBefore(js, fjs);
// })(document, "script", "facebook-jssdk");

//* ================= call now componentet =====================
const callNowComponent = document.querySelector(".call-now-section");
if (!!callNowComponent) {
    const callNowButton = callNowComponent.querySelector(".contact__button");
    const callNowField = callNowComponent.getElementsByClassName(
        "contact__text-field"
    )[0];
    const callNowTextField = callNowField.getElementsByTagName("input")[0];

    function activeSuccessMessage() {
        if (validCallNow()) {
            const successMessage = callNowComponent.querySelector(".success-message");
            successMessage.classList.add("open");
            setTimeout(() => {
                successMessage.classList.remove("open");
                callNowTextField.value = "";
            }, 3000);
        }
    }
    callNowButton.addEventListener("click", activeSuccessMessage);
}

//* ==================== validation call now ===========================
const callNowC = document.querySelector(".call-now-section");
if (!!callNowC) {
    const callNowField = callNowC.getElementsByClassName(
        "contact__text-field"
    )[0];
    const callNowTextField = callNowField.getElementsByTagName("input")[0];
    const callNowButton = callNowC.getElementsByClassName("contact__button")[0];
    callNowTextField.setAttribute("onkeypress", "return valideKey(event);");

    function valideKey(evt) {
        // code is the decimal ASCII representation of the pressed key.
        var code = evt.which ? evt.which : evt.keyCode;

        if (code == 8) {
            // backspace.
            return true;
        } else if (code === 43) {
            // plus
            return true;
        } else if (code >= 48 && code <= 57) {
            // is a number.
            // alert("valid keys");
            return true;
        } else {
            // other keys.
            // alert("other keys");
            return false;
        }
    }

    function validCallNow() {
        let language =
            window.location.pathname.split("/")[1] === "en" ? "en" : "es";

        const number = callNowTextField.value;
        // const pattern = /^\+*[0-9]+$/;
        const pattern = /^\+*[1-9][0-9]+$/i;
        if (number && pattern.test(number) && number.length <= 13) {
            callNowField.classList.remove("error");
            callNowField.getElementsByClassName("error")[0]?.remove();
            return true;
        } else {
            callNowField.classList.add("error");
            callNowField.getElementsByClassName("error")[0]?.remove();
            let spanError = document.createElement("span");
            spanError.className = "error";
            let spanErrorText = document.createTextNode(
                language === "es" ?
                "Ingrese un número de teléfono valido" :
                "Enter a valid Phone Number"
            );
            spanError.appendChild(spanErrorText);
            callNowField.append(spanError);
            return false;
        }
    }

    const sectionsNav = {
        "/": "Página principal",
        "/success-stories/web-agp/": "Casos de éxito/AGP",
        "/success-stories/mobile-sr/": "Casos de éxito/APP Mina",
        "/success-stories/cloud-perufarma/": "Casos de éxito/Peru Farma",
        "/success-stories/cloud-crp/": "Casos de éxito/CRP Radios",
        "/solutions/": "Soluciones",
        "/what-we-do/software-development": "Que hacemos/Desarrollo de software",
        "/what-we-do/cloud": "Que hacemos/Computación en nube",
        "/what-we-do/software-as-a-service/": "Que hacemos/Software as a service",
        "/what-we-do/automation/": "Que hacemos/Automatización",
        "/what-we-do/data-analytics/": "Que hacemos/Análisis de datos",
        "/what-we-do/cibersecurity/": "Que hacemos/Ciberseguridad",
        "/what-we-do/training/": "Que hacemos/Capacitaciones",
        "/what-we-do/licenses/": "Que hacemos/licenciamiento",
        "/what-we-do/ai-factory/": "Que hacemos/AI Factory",
        "/what-we-do/landing/": "Información",
        "/contact/": "Contáctanos",
        "/landing/": "Contáctanos",
        "/en/": "Página principal",
        "/en/success-stories/web-agp/": "Casos de éxito/AGP",
        "/en/success-stories/mobile-sr/": "Casos de éxito/APP Mina",
        "/en/success-stories/cloud-perufarma/": "Casos de éxito/Peru Farma",
        "/en/success-stories/cloud-crp/": "Casos de éxito/CRP Radios",
        "/en/solutions/": "Soluciones",
        "/en/what-we-do/software-development": "Que hacemos/Desarrollo de software",
        "/en/what-we-do/cloud": "Que hacemos/Computación en nube",
        "/en/what-we-do/software-as-a-service/": "Que hacemos/Software as a service",
        "/en/what-we-do/automation/": "Que hacemos/Automatización",
        "/en/what-we-do/data-analytics/": "Que hacemos/Análisis de datos",
        "/en/what-we-do/cibersecurity/": "Que hacemos/Ciberseguridad",
        "/en/what-we-do/training/": "Que hacemos/Capacitaciones",
        "/en/what-we-do/licenses/": "Que hacemos/licenciamiento",
        "/en/what-we-do/ai-factory/": "Que hacemos/AI Factory",
        "/en/contact/": "Contáctanos",
    };

    function getKeySection(cadena) {
        var keysSectionNav = Object.keys(sectionsNav).reverse();
        return keysSectionNav.find((key) => {
            if (key === cadena) {
                return true;
            } else {
                var regExpKey = new RegExp(key);
                var regExpCadena = new RegExp(cadena);
                if (regExpKey.test(cadena)) {
                    return true;
                } else if (regExpCadena.test(key)) {
                    return true;
                } else {
                    return false;
                }
            }
        });
    }

    callNowButton.addEventListener("click", () => {
        if (validCallNow()) {
            sendEmailByPhone(callNowTextField.value);
        }
    });

    function sendEmailByPhone(phone) {
        //* función para enviar correo en llamanos
        const language =
            window.location.pathname.split("/")[1] === "en" ? "en" : "es";
        const href = window.location.href;
        const url = "https://webvaltecenviomail.azurewebsites.net/api/email";
        const keySection = getKeySection(window.location.pathname);
        let form = JSON.stringify({
            names,
            email,
            phone,
            interested,
            comment,
            section: 'Llámame ahora',
            url: href,
            organization: company,
            type: 2,
            language,
        });

        fetch(url, {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: form,
            })
            .then(function(res) {
                console.log(res);
            })
            .catch(function(error) {
                console.log(error.response);
            });
    }
}
